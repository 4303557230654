<template>
    <div class="rb-view">user statistic</div>
</template>

<script>
    export default {
        name: "user-statistic"
    }
</script>

<style scoped>

</style>
